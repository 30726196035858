/* Define default (light mode) variables */
:root {
  --background-color: #e5ecf3;
  --hover-background-color: #e8d0b8;
  --text-color: #000;
}

/* Override variables for dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #746b6b;
    --hover-background-color: #b85f17;
    --text-color: #fff;
  }
}

/* Apply the variables */
.trigger-button {
  padding: 8px 16px; /* Adjust padding to increase clickable area */
  background-color: var(--background-color); /* Use the background color variable */
  color: var(--text-color); /* Use the text color variable */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.trigger-button:hover {
  background-color: var(--hover-background-color); /* Use the hover background color variable */
}